body {
    display: block;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.home {
    width: 100vw;
}
