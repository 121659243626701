.App {
  text-align: center;
  background-color: black;
}

* {box-sizing: border-box;}
.slide_vb {display: block;}
.home{
 height: 100vh;
}
/* Slideshow container */
.slideshow-container {
  max-width: 100vw;
  position: relative;
  margin: auto;
}

/* Fading animation */
.fade {
	//animation: blur_vb 2s linear;
  -webkit-transition: opacity 2s ease-in-out;
  transition: opacity 2s ease-in-out;
  opacity: 0;
}

@keyframes blur_vb{
  from{
    filter: blur(3px);
    opacity: 0.4;
  }
  to{
    filter: blur(0px);
    opacity: 1;
  }
}

.img_vb{
  z-index: 777;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size:  cover;

}

.hidden {

  opacity: 0;
  transition: display 0.0s, opacity 1s linear;
}

.visible {

  opacity: 1;
  transition: display 0s, opacity 2s linear;
}


#logo{
    width: 55vh;
    display: block;
    height: 35vh;
    position: absolute;
    left: 10vh;
    bottom: 0;
    z-index: 800;
  }

.social{
  z-index: 10000;
  position: absolute;
  bottom: 5vh;
  right: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 260px;
}

.social .links{
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.social .links a {
  background-size: 3rem 3rem;
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
}
@media (orientation: portrait) and (max-width: 749px){
  .social {
      left: 18vw;
  }

  #logo {
      top: 70%;
      width: 65vw;
      height: auto;
      left: 18vw;

  }
  .img_vb{
    z-index: 777;
    width: 100vw;
    height: 50vh;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size:  cover;

  }
}
